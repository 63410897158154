import { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import { faCookieBite, faEnvelope, faQuestion, faShieldHalved, faUserSecret } from '@fortawesome/free-solid-svg-icons';

import { colorStack } from '../../style/colorStack';
import { fontSizeAndHeight } from '../../style/fontSizes';

const Wrapper = styled.footer`
    width: 100%;
`;

const Inner = styled.div`
    padding: 1rem;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem 1.5rem;
    > div {
        text-align: center;
    }
    a {
        color: ${colorStack.darkGrey};
        transition: color .3s;
        ${fontSizeAndHeight[16]};
        svg {
            margin-right: .2rem;
        }
        &:hover {
            color: ${colorStack.middleGrey};
        }
        img {
            width: 20px;
        }
    }
`;

export const Footer: FC = () => {
    const intl = useIntl();
    return (
        <Wrapper>
            <Inner>
                <Link to="/cookies"><FontAwesomeIcon icon={faCookieBite} />
                    {intl.formatMessage({ id: 'footer.cookies' })}
                </Link>
                <Link to="/privacy"><FontAwesomeIcon icon={faShieldHalved} />
                    {intl.formatMessage({ id: 'footer.privacy' })}
                </Link>
                <Link to="/terms"><FontAwesomeIcon icon={faShieldHalved} />
                    {intl.formatMessage({ id: 'footer.terms' })}
                </Link>
                <Link to="/about"><FontAwesomeIcon icon={faUserSecret} />
                    {intl.formatMessage({ id: 'footer.about' })}
                </Link>
                <Link to="/contact"><FontAwesomeIcon icon={faEnvelope} />
                    {intl.formatMessage({ id: 'footer.contact' })}
                </Link>
                {/* <Link to="/help"><FontAwesomeIcon icon={faQuestion} />
                    {intl.formatMessage({ id: 'footer.help' })}
                </Link> */}
                <a href="https://asginfo.pl/" target="_blank">Czym jest Airsoft?</a>
                <a href="https://www.facebook.com/platforma.playair.pro/" target="_blank"><img src={`${process.env.PUBLIC_URL}/img/facebook-logo.png`} alt="Facebook logo" loading="lazy" /></a>
            </Inner>
        </Wrapper>
    );
};