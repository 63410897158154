import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { getCookie, setCookie } from '../../tools/cookies';
import { colorStack } from '../../style/colorStack';
import { Button } from './Buttons/Button';
import { IState } from '../../reducers';
import { IUserProfileReducer } from '../../reducers/profileReducer';
import { media } from '../../style/breakpoints';

const Wrapper = styled.div`
    position: fixed;
    margin: auto;
    top: 30%;
    left: 0;
    right: 0;
    height: auto;
    width: 90%;
    padding: 1rem;
    background: ${colorStack.white};
    box-shadow: 0px 8px 32px rgba(0,0,0, .2);
    z-index: 9999;
    border-radius: 16px;
    ${media.desktop`
        width: 50%;
    `}
`;

const Buttons = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
`;

const SpecialInfo: React.FC = () => {
    const intl = useIntl();
    const { userProfile } = useSelector<IState, IUserProfileReducer>(state => ({
        ...state.profile
    }));
    const [showBanner, setShowBanner] = useState<boolean>(false);

    useEffect(() => {
        const isCookie = getCookie('SpecialPlayAirCookie');
        if (!isCookie) {
            setShowBanner(true);
        }
    }, []);

    const closeBanner = () => {
        setCookie('SpecialPlayAirCookie', true, 365);
        setShowBanner(false);
    };

    const likeUs = () => {
        window.open('https://www.facebook.com/platforma.playair.pro', '_blank');
        closeBanner();
    };

    return (
        <>
            {(showBanner && userProfile?.id) ? (
                <Wrapper>
                    <p>
                        <strong>Drogi {userProfile?.firstName || ''} {userProfile?.nickName || ''} {userProfile?.lastName || ''},</strong>
                        <br />
                        <br />
                        Bardzo dziękujemy Ci za korzystanie z platformy Playair.pro! Jeśli doceniasz nasze działania i chcesz wspomóc rozwój strony,
                        gorąco zachęcamy do polubienia naszego profilu na Facebooku. Dzięki temu będziesz na bieżąco ze wszystkimi nowościami, a my będziemy
                        mogli jeszcze skuteczniej dostarczać Ci wartościowe treści.
                        <br />
                        <br />
                        Dziękujemy za Twoje wsparcie!
                    </p>
                    <Buttons>
                        <Button onClick={likeUs} schemmaType="BLUE" icon={faThumbsUp}>Polub nas!</Button>
                        <Button onClick={closeBanner} schemmaType="CANCEL">{intl.formatMessage({ id: 'cookiesBanner.button' })}</Button>
                    </Buttons>
                </Wrapper>
            // tslint:disable-next-line:no-null-keyword
            ) : null}
        </>
    );
};

export default SpecialInfo;