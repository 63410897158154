import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled, { css } from 'styled-components';

import { colorStack } from '../../../style/colorStack';
import { fontSizeAndHeight } from '../../../style/fontSizes';
import { Spinner } from '../Spinner/Spinner';

const Wrapper = styled.button<{ schemmaType: 'NORMAL' | 'ALERT' | 'CANCEL' | 'BLUE'; noMargin: boolean; }>`
    border-radius: 4px;
    height: 30px;
    padding: 0 .8rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    background: ${colorStack.green};
    color: ${colorStack.white};
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    ${fontSizeAndHeight[16]};
    transition: all .3s;
    &:hover {
        background: ${colorStack.blue};
    };
    ${props => props.schemmaType === 'ALERT' && css`
        background: ${colorStack.middleRed};
        &:hover {
            background: ${colorStack.darkRed};
        };
    `}
    ${props => props.schemmaType === 'CANCEL' && css`
        background: ${colorStack.middleGrey};
        &:hover {
            background: ${colorStack.darkGrey};
        };
    `}
    ${props => props.schemmaType === 'BLUE' && css`
        background: ${colorStack.middleBlue};
        &:hover {
            background: ${colorStack.darkBlue};
        };
    `}
    ${props => props.disabled && css`
        background: ${colorStack.middleGrey};
        &:hover {
            background: ${colorStack.middleGrey};
        };
    `};
    svg {
        margin-left: .5rem;
        ${props => props.noMargin && css`
            margin: 0;
        `}
    };
    > div {
        width: 10px;
        margin-left: .5rem;
    }
`;

interface IProps {
    children?: React.ReactNode | string;
    disabled?: boolean;
    icon?: IconDefinition;
    loading?: boolean;
    schemmaType?: 'NORMAL' | 'ALERT' | 'CANCEL' | 'BLUE'
    onClick(): void;
}

export const Button: FC<IProps> = props => {
    return (
        <div>
            <Wrapper noMargin={!props.children} schemmaType={props.schemmaType || 'NORMAL'} type="button" onClick={props.disabled ? undefined : props.onClick} disabled={props.disabled}>
                {props.children}
                {props.icon && <FontAwesomeIcon icon={props.icon} />}
                {props.loading && <Spinner size="buttonSize" />}
            </Wrapper>
        </div>
    );
};